import React from "react"
import Layout from "../../components/layout"

import {
  CardCabinAtardecer,
  CardCabinGaviota,
  CardCabinPerla,
  CardCamp,
  CardClima,
  CardHabitaciones,
} from "../../components/Cards"
import Seo from "../../components/seo"

const ChoosePage = () => {
  return (
    <Layout>
      <Seo title="Cabañas" />

      <div className="">
        <h2 className="title lg:subtitle text-center pt-16 pb-8 lg:py-0">
          Elegí tu cabaña
        </h2>
        <div
          className={`flex lg:flex items-center justify-center flex-wrap gap-8 lg:gap-16 lg:max-w-6xl mx-auto py-8 lg:pb-16 px-2`}
        >
          <CardCabinGaviota />
          <CardCabinPerla />
          <CardCabinAtardecer />
          <CardClima />
          <CardHabitaciones />
          <CardCamp />
        </div>
      </div>
    </Layout>
  )
}

export default ChoosePage
